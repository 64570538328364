'use client';

import { GoogleAnalytics, HotJar } from '@sushiswap/ui';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { useEnabledCookies } from './_common/cookies/use-enabled-cookies';
export function Trackers() {
  const enabledCookies = useEnabledCookies();
  const performanceEnabled = !!enabledCookies?.has('performance');
  return <>
      <VercelAnalytics data-sentry-element="VercelAnalytics" data-sentry-source-file="trackers.tsx" />
      <GoogleAnalytics enabled={performanceEnabled} data-sentry-element="GoogleAnalytics" data-sentry-source-file="trackers.tsx" />
      <HotJar enabled={performanceEnabled} data-sentry-element="HotJar" data-sentry-source-file="trackers.tsx" />
      <SpeedInsights data-sentry-element="SpeedInsights" data-sentry-source-file="trackers.tsx" />
    </>;
}